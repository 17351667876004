<template>
  <div class="wrapper">
    <el-card shadow="never" style="border: none">
      <div class="company">
        <div class="icon">
          <img src="./image/icon.png" alt="company" />
        </div>
        <div class="company_message">
          <p class="company_name">{{ detail.companyName }}</p>
          <p class="time">您的实名认证将在 {{ detail.expiryTime }} 到期</p>
          <div class="progress"></div>
        </div>
        <div class="status">已认证</div>
      </div>
      <div class="detail">
        <div class="title">企业类型信息</div>
        <el-row class="content_wrapper">
          <el-col :span="12" class="col">
            <span class="label">企业类型</span>
            <p class="value">{{ detail.companyType }}</p>
          </el-col>
          <el-col :span="12" class="col">
            <span class="label">证件类型</span>
            <p class="value">{{ detail.bussCode }}</p>
          </el-col>
        </el-row>

        <div class="title">营业执照</div>
        <el-row class="content_wrapper">
          <el-col :span="12" class="col">
            <span class="label">名称</span>
            <p class="value">{{ detail.companyName }}</p>
          </el-col>
          <el-col :span="12" class="col">
            <span class="label">统一社会信用代码（18位）</span>
            <p class="value">{{ detail.blicUscc }}</p>
          </el-col>
          <el-col :span="12" class="col">
            <span class="label">省份</span>
            <p class="value">{{ detail.blicProvince }}</p>
          </el-col>
          <el-col :span="12" class="col">
            <span class="label">市</span>
            <p class="value">{{ detail.blicCity }}</p>
          </el-col>
          <el-col :span="12" class="col">
            <span class="label">住所</span>
            <p class="value">{{ detail.blicAddress }}</p>
          </el-col>
          <el-col :span="12" class="col">
            <span class="label">经营范围</span>
            <p class="value">{{ detail.blicScope }}</p>
          </el-col>
          <el-col :span="12" class="col">
            <span class="label">营业期限（止）</span>
            <p class="value">{{ detail.blicValidityEnd }}</p>
          </el-col>
        </el-row>

        <div class="title">对公账户信息</div>
        <el-row class="content_wrapper">
          <el-col :span="12" class="col">
            <span class="label">银行账户</span>
            <p class="value">{{ detail.occBankAccount }}</p>
          </el-col>
          <el-col :span="12" class="col">
            <span class="label">开户银行</span>
            <p class="value">{{ detail.occBankName }}</p>
          </el-col>
          <el-col :span="12" class="col">
            <span class="label">开户支行</span>
            <p class="value">{{ detail.occBankChildName }}</p>
          </el-col>
        </el-row>

        <div class="title">法人证件</div>
        <el-row class="content_wrapper">
          <el-col :span="12" class="col">
            <span class="label">证件类型</span>
            <p class="value">{{ detail.lepCardType }}</p>
          </el-col>
          <el-col :span="12" class="col">
            <span class="label">姓名</span>
            <p class="value">{{ detail.lepName }}</p>
          </el-col>
          <el-col :span="12" class="col">
            <span class="label">证件号码</span>
            <p class="value">{{ detail.lepCardNo }}</p>
          </el-col>
          <el-col :span="12" class="col">
            <span class="label">有效期限（止）</span>
            <p class="value">{{ detail.lepValidityEnd }}</p>
          </el-col>
        </el-row>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getDetail } from './api'

export default {
  data() {
    return {
      detail: {},
    }
  },
  mounted() {
    this._getDetail()
  },
  methods: {
    async _getDetail() {
      try {
        this.detail = await getDetail()
      } catch (err) {
        console.error(err)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.wrapper {
  padding: 20px 0;

  .company {
    display: flex;
    margin: 20px 0 30px;

    .icon {
      margin-right: 32px;
    }

    .company_message {
      .company_name {
        font-size: 16px;
        color: #333;
        line-height: 24px;
        margin-bottom: 32px;
      }

      .time {
        font-size: 14px;
        color: #999;
        line-height: 24px;
        margin-bottom: 18px;
      }

      .progress {
        border-radius: 10px;
        height: 8px;
        background-image: linear-gradient(#8fe03e, #4bbe4b);
      }
    }

    .status {
      width: 80px;
      background: #4bbe4b;
      border-radius: 16px;
      color: #fff;
      line-height: 28px;
      height: 28px;
      text-align: center;
    }
  }

  .detail {
    .title {
      background: #f6f8fc;
      line-height: 40px;
      font-size: 14px;
      color: #333;
      padding-left: 16px;
      font-weight: 600;
    }

    .content_wrapper {
      .col {
        display: flex;
        padding-left: 16px;

        .label {
          width: 170px;
          font-size: 14px;
          color: #999;
          line-height: 40px;
        }
        .value {
          flex: 1;
          font-size: 14px;
          color: #333;
          line-height: 40px;
        }
      }
    }
  }
}
</style>
